import React from "react"

const Footer = () => (
  <footer>
    <a href="mailto:orlowski.blazej@gmail.com">orlowski.blazej@gmail.com</a>
    <p>&copy; {new Date().getFullYear()} All rights reserved</p>
  </footer>
)

export default Footer
