import React, { useEffect } from "react"
import { Link } from "gatsby"

const Header = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        var nav = document.getElementsByTagName("header")[0]

        if (window.scrollY > 1) {
          nav.classList.add("shrink")
        } else {
          nav.classList.remove("shrink")
        }
      })
    }
  })
  return (
    <header>
      <Link className="header-logo" to="/">
        <img
          src={require("../assets/images/logo.svg").default}
          alt="WebEagle Web Developer"
        />
      </Link>
      <nav>
        <ul>
          <li>
            <a href="#about">Home</a>
          </li>
          <li>
            <a href="#career">Career</a>
          </li>
          <li>
            <a
              href="http://www.linkedin.com/in/blazej-orlowski-4602b9b2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../assets/images/ico_linkedin.svg").default}
                alt="LinkedIn Blazej Orlowski"
              />
            </a>
          </li>

          <li>
            <a
              href="https://www.strava.com/athletes/blazej-orlowski"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../assets/images/ico_strava.svg").default}
                alt="Strava Blazej Orlowski"
              />
            </a>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
